#nav-dropdown-item {
    font-size: 14px;
    font-weight: 400;
    background-color: #E94E1B;
    padding: 15px 20px;
    margin: auto;
    color:white;
}

#nav-dropdown-item-radius {
    border-radius: 20px;
}

#collapsible-nav {
    /* color: #E94E1B;*/
    color: black;
}
#collapsible-nav:hover{
/*     color: #1D70B8; */
    color: #E94E1B;
}

.dropdown-menu {
    padding: 0px;
    border-radius: 20px;
    color: white;
}

#navbar-static {
    background-color: white;
}

#navbar-moved {
    background-color: white;
}

.dropdown {
    position: relative;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 0px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #E94E1B;
    color: white;
    font-size: 14px;
    font-weight: 400;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 12px 16px;
    z-index: 1000;
    white-space: nowrap;
}

.dropdown-content::before {
    content: "";
    position: absolute;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #E94E1B;
}

.dropdown:hover .dropdown-content {
    display: flex;
    top: 100% !important;
    left: 0% !important;
    right: auto !important;
}

.navbar-main-link{
    font-weight: 500;
    font-size: 14px;
}

.navbar-clr-nrml {
    color: black;
}

.navbar-clr-trans {
    color: #ecebeb;
}

.navbar-main-link:hover {
    color: #E94E1B;
    text-decoration: none;
}

.navbar-sub-link{
    font-weight: 500;
    font-size: 14px;
    color: white;
}

.navbar-sub-link:hover {
    color: white;
}

.fixedElement {
    background-color: rgba(0,0,0,0);
    position:fixed;
    top:0;
    width:100%;
    z-index:100;
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 80px;
    padding: 0px 30px;
}