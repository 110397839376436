@media (min-width: 768px){
    .curveSectionTop {
        border-top-left-radius: 20%;
    }

    .curveSectionBottom {
        border-bottom-right-radius: 20%;
    }
    .customFooter {
        display: block;
        box-sizing: border-box;
        height: 80px;
        background-color: #F5F5F5;
        clip-path: ellipse(75% 100% at 28.75% 100%);
        display: flex;
        align-content: center;
    }
}

.curved-div svg {
    display: block;
    width: 100%;
    height: 500px;
    position: relative;
}