.container{
    padding: '50px 10px 0 10px';
    max-width:'90%';
}

.image-text-div-sub{
    display: flex;
    align-items: space-between;
}

.image-div {
    width: 40%;
    display: inline-block;
}

.text-div {
    width: 40%;
    vertical-align: top;
    text-align: start;
    margin: 5px 50px 5px 50px;
}